@import '../variables.scss';
// These styles are for elements whose respective purposes pertain exclusively to forms.

// -------------------------------------------------------------------------------------------------
// Form Field Header
// -------------------------------------------------------------------------------------------------

.formFieldHeader {
    margin-bottom: 0.25rem;

    &-element {
        margin-right: 0.5em;

        &--label {
            font-size: 14px;
            font-weight: bold;
        }

        &--hint {
            font-size: smaller;
            vertical-align: bottom;
        }
    }
}

// -------------------------------------------------------------------------------------------------
// Form Field Actions - The list of clickable buttons below a form field.
// -------------------------------------------------------------------------------------------------

.formFieldActions {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    min-height: 1em; // Makes sure that even with no contents, spacing of doc stays the same.

    &-action {
        overflow: hidden;
        white-space: nowrap;
        &:hover {
            text-decoration: underline;
        }
        &-label {
            &-icon {
                margin-right: 0.5em;
            }
        }
        &:not(:first-child) {
            margin-left: 2em;
        }
    }
}

// -------------------------------------------------------------------------------------------------
// Form Field Input Wrapper - Designed to look like a Mat-Form-Field appearance="outline"
// -------------------------------------------------------------------------------------------------

.formFieldControl {
    border: 0.0625rem solid #000000; // For rem (16px) this is 1px; rem makes page more accessible
    border-radius: 0.25rem;
    opacity: 0.5;
    transition: opacity ease-out 0.125s, border-color ease-out 0.125s;

    // --active is in case you need to manipulate this state manually
    // 'not' is to make disabled form fields appropriately unresponsive.
    &:focus-within:not(.formFieldControl--disabled), &--active:not(.formFieldControl--disabled) {
        opacity: 1;
        border-color: #1c84c6;
        border-width: 0.125rem;

        // Elements directly inside the formField need to adjust their padding to prevent the UI
        // from moving around every time something is selected.
        & .formFieldControl-input, & .formFieldControl-select {
            outline: none;
            padding: calc(1em - 0.0625rem);
        }
    }

    &:hover {
        opacity: 1;
    }

    // Disabled elements don't respond to mouse, have darkened color.
    &--disabled {
        opacity: 0.5;
        background-color: rgb(245, 245, 245);
        border-color: rgba(245, 245, 245, 0.5);
        
        &:hover {
            opacity: 0.5;
        }
    }

    // .formFieldControl-input is for input HTML elements
    &-input {
        background: transparent;
        border-radius: 0.25rem;
        border-style: none;
        padding: 1em;
        width: calc(100% - 2em);

        &:hover {
            background-color: rgb(245, 245, 245);
        }
    }

    // .formFieldControl-select is for arbitrary components that are meant to be displayed as
    // a select option (i.e. the entity picker, which will implement this eventually). The border/
    // margin of these components should be 0, then wrapped in a div with this class, to allow it to
    // do its work.
    &-select {
        padding: 1em;
        border-radius: 0.25rem;
        border-style: none;
        width: calc(100% - 2em);

        &:hover {
            background-color: rgb(245, 245, 245);
        }

        // Standard styles for use in selection elements.
        &-element {
            &--label {
                font-size: 14px;
                font-weight: bold;
            }

            &--subTitle {
                font-size: 12px;
                color: gray;
            }
        }
    }
}

// -------------------------------------------------------------------------------------------------
// Form Field Text - font styles
// -------------------------------------------------------------------------------------------------

.formFieldText-error {
    color: color(warn);
    font-weight: 600;
}