// Use must be before everything else.
@import './styles/index.scss';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.tooltip {
    @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
    @apply visible z-50
}

/* You can add global styles to this file, and also import other style files */
@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,700&display=swap');
$custom-typography: mat-typography-config(
    $font-family: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    $headline: mat-typography-level(30px, 30px, 300),
    /* h1 */ $title: mat-typography-level(24px, 24px, 300),
    /* h2 */ $subheading-2: mat-typography-level(16px, 16px, 600),
    /* h3 */ $subheading-1: mat-typography-level(14px, 14px, 600),
    /* h4 */ $body-1: mat-typography-level(13px, 13px, 300) /* body */
);
@include mat-core($custom-typography);

@import './variables.scss';

$ptkr-theme-primary: mat-palette($ptkr-primary-palette);
$ptkr-theme-accent: mat-palette($ptkr-accent-palette);
$ptkr-theme-warn: mat-palette($ptkr-warn-palette);

$ptkr-theme: mat-light-theme($ptkr-theme-primary, $ptkr-theme-accent, $ptkr-theme-warn);

$default-font-size: 13px;
@include angular-material-theme($ptkr-theme);
html {
    height: 100%;
}
body {
    margin: 0px;
    height: 100%;
    font-family: font(primary);
    font-size: $default-font-size;
    h1 {
        font-size: 40px;
        font-weight: 700;
        margin-top: 0px;
        color: black;
    }
    h2 {
        font-size: 24px;
        font-weight: 700;
    }
    h3 {
        font-size: 16px;
        font-weight: 700;
    }
    h4 {
        font-size: 14px;
        font-weight: 700;
    }
    h5 {
        font-size: 12px;
        font-weight: 700;
    }
    h6 {
        font-size: 10px;
        font-weight: 300;
    }
    ptkr-root {
        height: 100%;
        display: block;
    }
    form {
        ptkr-field-group {
            mat-form-field {
                width: 100%;
            }
        }
    }

    .mat-menu-panel {
        max-width: 300px;
    }

    .mat-expansion-panel-header .mat-content {
        display: flex;
        justify-content: space-between;
        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
            flex-grow: 1;
        }
        .mat-expansion-panel-header-description {
            margin-right: 1em;
            justify-content: end;
        }
    }

    .action-buttons {
        text-align: right;
    }
    div {
        // indented the left and right side of a div
        &.indented-box {
            @media (min-width: breakpoint(phone-landscape)) {
                padding-left: 32px;
                padding-right: 32px;
            }
        }

        // pads the sides and top and bottom of a div
        &.padded-box {
            padding: 0.75em 1em;
        }

        // breaks content vertically with a 32px horizontal barrier
        &.content-break {
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            height: 32px;
        }
    }
    .mat-card {
        border-radius: none !important;
        border-bottom: solid #ccc 2px;
        margin-bottom: 32px;
        padding: 32px !important;
        box-shadow: none !important;
        &.overlay {
            box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.2) !important;
        }
    }
    hr {
        margin: 32px 0px;
        border: 0;
        border-bottom: 1px dashed #bbb;
    }
    .mat-menu {
        font-weight: initial;
        font-size: 13px !important;
    }
    .mat-tree-node {
        min-height: 40px;
    }
    .mat-option {
        font-size: $default-font-size !important;
        height: 48px !important;
    }
    .mat-tab-header {
        border-bottom-style: none !important;
    }
    //.mat-tab-label {
    //    font-size: $default-font-size !important;
    //    border-top: 2px solid transparent;
    //    border-top-left-radius: 4px;
    //    border-top-right-radius: 4px;
    //    min-width: 0;
    //}
    .mat-tab-label-active {
        background-color: white !important;
        opacity: 1 !important;
        border-top: 2px solid color(accent) !important;
    }
    .mat-tab-label {
        height: 2.25em;
        border-top: 2px solid transparent;
    }
    .mat-ink-bar {
        color: transparent !important;
        border-color: transparent !important;
        background-color: transparent !important;
    }

    label.field-label {
        margin-top: 8px;
        font-weight: bold;
    }
    .mat-input-element {
        vertical-align: bottom;
        text-align: inherit;
        line-height: 1.5em;
        background: none !important;
    }
    .mat-form-field-infix {
        border-top-width: 0px;
    }
    .mat-form-field-outline {
        top: 0 !important;
    }
    .mat-form-field-suffix {
        top: 0 !important;
        bottom: 0 !important;
    }

    .mat-flat-button,
    .mat-button {
        border-radius: 4px;
        i {
            margin-right: 4px !important;
        }
    }

    table.mat-table {
        tr.mat-row:not(.ng-trigger-detailExpand):not(.detail-row):nth-child(odd) {
            background-color: #f9f9f9;
        }
        .element-row {
            position: relative;
            overflow: initial;
        }
        .element-row {
            cursor: pointer;
        }

        .element-row:not(.expanded):hover {
            background: #f5f5f5;
        }

        .element-row.expanded {
            border-bottom-color: transparent;
        }

        &.expandable-rows {
            .expand-indicator svg {
                transform: rotate(0deg);
                transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1);
            }

            .expanded .expand-indicator svg {
                transform: rotate(90deg);
            }
        }
    }
    .checkbox-checked.mat-primary .mat-checkbox-background {
        background-color: color(primary);
    }
    .checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: color(accent);
    }
    .checkbox-checked.mat-warn .mat-checkbox-background {
        background-color: color(warn);
    }
    .checkbox-checked .mat-checkbox-mixedmark {
        transform: scaleX(1) rotate(-45deg);
    }
    .checkbox-checked .mat-checkbox-checkmark {
        opacity: 1;
    }
    .checkbox-checked .mat-checkbox-checkmark-path {
        stroke-dashoffset: 0;
    }
    .select-entity-overlay-panel {
        width: 100%;
    }
    .notification-overlay-panel {
        max-height: calc(100% - 50px);
        overflow: auto;
    }
    .order-toner-overlay-panel {
        width: 100%;
        margin-top: -20%;
    }
    .add-device-manually-overlay-panel {
        width: 90%;
    }
    .view-invoice-modal-panel {
        width: 80vw;
        max-height: 95vh;

        @media (max-width: breakpoint(mobile-portrait)) {
            height: 100%;
            max-height: 100%;
            width: 100%;
            max-width: 100% !important;
        }
    }
    .add-shipping-address-modal-panel {
        max-width: 520px !important;
        width: 100%;
        overflow-y: auto;
    }
    .add-payment-method-modal-panel {
        max-width: 520px !important;
        width: 100%;
    }
    .checkout-overlay {
        @media (max-width: breakpoint(mobile-portrait)) {
            max-width: 100% !important;
            width: 100%;
            height: 100%;

            .mat-dialog-content {
                max-height: calc(100% - 12px);
            }
        }
    }
    .bulk-settings-overlay {
        .mat-dialog-content {
            max-height: 80vh;
        }

        @media (max-width: breakpoint(mobile-portrait)) {
            max-width: 100% !important;
            height: 100%;

            .mat-dialog-content {
                max-height: calc(100% - 12px);
                padding-left: 0;
                margin: -24px -24px 0;
            }

            .mat-dialog-actions {
                margin-top: 0;
            }

            ptkr-settings-step {
                float: none;
            }

            .mat-checkbox-label {
                white-space: normal;
            }

            .mat-tree {
                overflow-x: auto;

                & > mat-nested-tree-node {
                    display: inline-block;
                }

                .mat-checkbox-label,
                li,
                ul {
                    white-space: nowrap;
                }
            }
        }
    }

    .mat-snack-bar-container {
        background-color: color(accent);

        .mat-simple-snackbar-action button {
            color: white;
            border: 0.55px solid rgba(255, 255, 255, 0.4);
        }
    }

    table.settings {
        td {
            height: 48px;
            .mat-form-field-wrapper {
                padding: 0;
            }
        }
    }

    .label {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        border-radius: 2px;
        display: inline-block;
        // overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: text-bottom;
        white-space: pre;
        padding: 1px 0;
        margin-right: 20px;

        &.tab-label {
            padding: 0px 5px;
            margin-left: 5px;
        }

        &.padding-mod {
            padding: 1px 8px;
        }

        &.default {
            border: 1px solid #d8d8d8;
            background-color: #fff;
            margin-right: 10px;
        }

        &.sent {
            background-color: rgb(237, 239, 241);
            color: rgb(26, 26, 27);
        }

        &.received {
            background-color: #f6ab5a;
            color: #000000;
            // background-color: rgb(255, 229, 48);
            // color: rgb(26, 26, 27);
        }

        &.running {
            background-color: #f6ab5a;
            color: #ffffff;
        }

        &.completed {
            background-color: #1CA35F;
            color: #ffffff;
        }

        &.rescheduled {
            background-color: #f6ab5a;
            color: #ffffff;
        }

        &.failed {
            background-color: #ed5764;
            color: #ffffff;
        }

        &.stopped {
            border: 1px solid #ed5764;
            color: #ed5764;
        }
    }

    .online {
        color: #1CA35F;
    }

    .offline {
        color: #ed5764;
    }

    .warning {
        color: #f6ab5a;
    }

    .warning-border {
        color: #f6ab5a;
        border: 1px solid #f6ab5a;
        border-radius: 2px;
    }

    .clearfix:after {
        content: '\00A0';
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
    .clearfix {
        display: inline-block;
    }
    html[xmlns] .clearfix {
        display: block;
    }
    * html .clearfix {
        height: 1%;
    }
    .clearfix {
        display: block;
    }
    .pull-left {
        float: left;
    }
    .pull-right {
        float: right;
    }

    // KV List Styles
    //.kv-list {
    //    display: table;
    //    border-collapse: collapse;
    //    width: 100%;
    //    margin-bottom: 20px;
    //}
    //
    //.kv-list-item-group {
    //    display: table-row-group;
    //}
    //
    //.kv-list-item:nth-child(odd) {
    //    background-color: #fafafa;
    //}
    //.kv-list-item {
    //    display: table-row;
    //}
    //
    //.kv-list-key {
    //    font-family: 'Roboto', sans-serif;
    //    font-weight: 500;
    //    font-size: 11px;
    //    letter-spacing: normal;
    //    line-height: 12px;
    //    color: rgb(0,0,0);
    //    text-transform: inherit;
    //    width: 160px;
    //}
    //
    //.p6n-kv-list-key, .p6n-kv-list-values {
    //    background: none;
    //    border: 0;
    //    padding: 4px 8px 4px 12px;
    //    display: table-cell;
    //}
    //
    //.p6n-kv-list-values {
    //    font-size: 12px;
    //    line-height: 16px;
    //}
    //
    //.kv-list-value {
    //    font-family: 'Roboto', sans-serif;
    //    word-wrap: break-word;
    //    margin: 0 0 4px;
    //
    //    &:last-child {
    //        margin-bottom: 0;
    //    }
    //}

    .kv-list {
        display: table;
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 20px
    }

    // .kv-list-item {
    //     display: table-row
    // }

    .kv-list-item:nth-child(odd) {
        background-color: #fafafa
    }

    .kv-list-key, .kv-list-values {
        background: none;
        border: 0;
        padding: 4px 8px 4px 12px;
        display: table-cell;
    }

    .kv-list-key {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 11px;
        letter-spacing: normal;
        line-height: 12px;
        color: rgb(0,0,0);
        text-transform: inherit;
        width: 120px;
        vertical-align: middle;
    }

    .kv-list-key-indented {
        padding-left: 28px
    }

    .kv-list-values {
        font-size: 12px;
        line-height: 16px
    }

    .kv-list-value {
        font-family: 'Roboto', sans-serif;
        word-wrap: break-word;
        margin: 0 0 4px;
        display: inline-block;

        span {
            vertical-align: sub;
        }

        mat-icon {
            float: left;
            transform: scale(0.6)
        }
    }

    .kv-list-value:last-child {
        margin-bottom: 0
    }

    // Margin & Padding Helpers
    .m-t-0 {
        margin-top: 0px !important;
    }

    .m-t-10 {
        margin-top: 10px;
    }

    .m-b-0 {
        margin-bottom: 0 !important;
    }

    .m-b-10 {
        margin-bottom: 10px;
    }

    .m-b-16 {
        margin-bottom: 16px;
    }

    .m-t-20 {
        margin-top: 20px;
    }

    .m-t-25 {
        margin-top: 25px;
    }

    .m-b-20 {
        margin-bottom: 20px;
    }

    .m-b-25 {
        margin-bottom: 25px;
    }

    .m-r-5 {
        margin-right: 5px;
    }

    .m-l-5 {
        margin-left: 5px;
    }

    .p-t-3 {
        padding-top: 4px !important;
    }

    .p-b-3 {
        padding-top: 4px !important;
    }

    .p-l-25 {
        padding-left: 25px !important;
    }

    // Text designs
    a {
        color: #3367d6;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }

    no-padding-top-bottom {
        padding-top: 0;
        padding-bottom: 0;
    }

    .text-container {
        max-width: 512px
    }
}


.log-line {
    $info-color: #000000;
    $info-background-color: skyblue;
    $warning-color: #fff;
    $warning-background-color: salmon;
    $error-color: #fff;
    $error-background-color: crimson;

    &.hint-header {
        margin-top: 0;
        &.info {
            color: $info-background-color;
        }
        &.warning {
            color: $warning-background-color;
        }
        &.error {
            color: $error-background-color;
        }
    }
    &.highlight {
        &.info {
            background-color: $info-background-color;
            color: $info-color;
        }
        &.warning {
            background-color: $warning-background-color;
            color: $warning-color;
        }
        &.error {
            background-color: $error-background-color;
            color: $error-color;
        }
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear-both {
    clear: both;
}

.table-row-icon {
    width: 17px;
    height: 17px;
    font-size: 17px;
    margin-right: 5px;
}

.no-padding-dialog-container .mat-dialog-container {
    padding: 0;
}

.small-warning-icon {
    height: 18px;
    width: 18px;
    vertical-align: middle;
    font-size: 18px;
    margin-right: 0.1em;
    color: #f8ac59;
}

.monaco-editor {
    flex: 1 1 auto;
    height: 100%;
    width: -webkit-fill-available;
}
