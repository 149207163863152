mat-form-field {
    /* editable-field-form-field removes bottom margin + padding, adds some padding-right
       it is used by 'EditableFieldComponent' */
    &.editable-field-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 0;
            margin-bottom: 0;
            padding-right: 15px;
        }
    }
}

body div.mat-menu-panel.no-max-width {
    max-width: none !important;
}
